<template>
    <div>
        <b-card>
            <XcTable
                ref="shipmentsTable"
                endpoint="shipments"
                :columns="tableColumns"
                :buttons="tableButtons"
                :records-per-page="100"
                :search-enabled="true"
            />
        </b-card>
        <create-shipment-modal
            :ok-function="shipmentCreated"
        />
        <modal-confirm-vuexy
            text="Are you sure you want to delete this shipment?"
            title="Delete shipment?"
            :ok-function="deleteShipment"
        />
    </div>
</template>

<script>
import XcTable from '@/components/Misc/XcTable.vue'
import XcTableHelper from '@/components/Misc/XcTableHelper'
import CreateShipmentModal from '@/components/Modals/CreateShipmentModal.vue'
import ModalConfirmVuexy from '@/components/Modals/ModalConfirmVuexy'
import Axios from 'axios'
import {
    BCard,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Features from '@/services/Features'

export default {
    name: 'shipments.index',
    components: {
        XcTable,
        CreateShipmentModal,
        ModalConfirmVuexy,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
        BCard,
    },
    data() {
        return {
            tableButtons: [
                {
                    class: 'btn btn-primary',
                    icon: 'fa-plus',
                    label: 'Upload new shipment',
                    callback: this.createNewShipment,
                },
            ],
            defaultTableColumns: [
                {
                    type: XcTableHelper.TYPE_URL,
                    name: 'shipment_id',
                    title: 'Shipment ID',
                    field: 'shipment_id',
                    label: 'Shipment ID',
                    routeName: 'shipments.view',
                },
                {
                    type: XcTableHelper.TYPE_URL,
                    name: 'name',
                    title: 'Name',
                    field: 'name',
                    label: 'Name ID',
                    routeName: 'shipments.view',
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'country',
                    title: 'Country',
                    field: 'country',
                    label: 'Country',
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'created_at',
                    title: 'Created at',
                    field: 'created_at',
                    label: 'Created at',
                    sortByDefault: XcTableHelper.ORDER_BY_DESC,
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'updated_at',
                    title: 'Last updated at',
                    field: 'updated_at',
                    label: 'Last updated at',
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'boxes',
                    disableSort: true,
                    title: 'Boxes',
                    field: 'boxes',
                    label: 'Boxes',
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'mskus',
                    disableSort: true,
                    title: 'MSKUs',
                    field: 'mskus',
                    label: 'MSKUs',
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'total',
                    disableSort: true,
                    title: 'Total',
                    field: 'total',
                    label: 'Total',
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'shipped',
                    disableSort: true,
                    title: 'Processed',
                    field: 'shipped',
                    label: 'Processed',
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'received',
                    disableSort: true,
                    title: 'Received',
                    field: 'received',
                    label: 'Received',
                },
                {
                    type: XcTableHelper.TYPE_BADGE,
                    name: 'status',
                    title: 'Status',
                    field: 'status',
                    label: 'Status',
                },
            ],
            optionsColumns: [
                {
                    type: XcTableHelper.TYPE_BUTTONS,
                    disableSort: true,
                    title: '',
                    field: 'action',
                    label: 'Action',
                    buttons: [
                        {
                            class: 'mr-50',
                            icon: 'TrashIcon',
                            label: 'Delete',
                            callback: this.deleteShipmentConfirm,
                        },
                    ],
                }
            ],
            linnworksIntegrationColumns: [
                {
                    type: XcTableHelper.TYPE_BADGE,
                    name: 'linnworks-stock-removal',
                    title: 'Linnworks stock removal',
                    field: 'linnworks-stock-removal',
                    label: 'Linnworks stock removal',
                },
            ],
            shipmentToDelete: 0,
            isShipmentCreated: false,
        }
    },
    computed: {
        tableColumns() {
            let columns = this.defaultTableColumns
            if (Features.checkFeature('linnworks_integration')) {
                columns = [...columns, ...this.linnworksIntegrationColumns]
            }
            columns = [...columns, ...this.optionsColumns]

            return columns
        }
    },
    methods: {
        createNewShipment() {
            this.isShipmentCreated = false
            this.$root.$emit('bv::show::modal', 'createShipmentModal')
        },
        deleteShipmentConfirm(id) {
            this.shipmentToDelete = id
            this.$root.$emit('bv::show::modal', 'confirmModal')
        },
        deleteShipment() {
            new Promise((resolve, reject) => {
                Axios({ url: `${process.env.VUE_APP_API_HTTP_ROOT}shipments/delete`, data: { id: this.shipmentToDelete }, method: 'DELETE' })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }).then(() => {
                this.$refs.shipmentsTable.loadData()

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Success!',
                        icon: 'CheckIcon',
                        text: 'The shipment has been deleted successfully.',
                        variant: 'primary',
                    },
                })
            })
        },
        shipmentCreated() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Success!',
                    icon: 'CheckIcon',
                    text: 'Your shipment has been uploaded.',
                    variant: 'primary',
                },
            })

            this.isShipmentCreated = true
            this.$refs.shipmentsTable.loadData()
        }
    },
}
</script>
