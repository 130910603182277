<template>
    <div>
        <b-modal
            id="createShipmentModal"
            ref="createShipmentModal"
            title="Upload new shipment"
            ok-title="Upload"
            cancel-title="Cancel"
            cancel-variant="outline-secondary"
            centered
            modal-class="modal-primary"
            @ok="uploadFile"
        >
            <b-card-text>
                <p>Please select a *.tsv file and hit the upload button to upload new shipment.</p>
                <b-form-file
                    id="s-file"
                    v-model="file"
                    accept=".tsv"
                    placeholder="Choose a .tsv file or drag and drop it here..."
                />
            </b-card-text>
        </b-modal>
    </div>
</template>

<style>
</style>

<script>
import Axios from 'axios'
import {
    BModal,
    BCardText,
    BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BCardText,
        BModal,
        BFormFile,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
    },
    props: {
        okFunction: {
            type: Function,
            default() { },
        },
    },
    data() {
        return {
            validFileExt: '.tsv',
            loading: false,
            fileName: null,
            file: null,
            fileContent: null,
            fileErrors: [],
        }
    },
    methods: {
        filePickedDragAndDrop(fileInfo) {
            const file = fileInfo
            if (file) {
                this.fileName = file.name
                this.getBase64(file).then(
                    data => {
                        this.fileContent = data
                        this.$forceUpdate()
                    },
                )
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result.split(',').pop())
                reader.onerror = error => reject(error)
            })
        },
        validateFileName(name) {
            return name.indexOf(this.validFileExt) !== -1
        },
        uploadFile() {
            this.loading = true
            this.fileErrors = []
            this.filePickedDragAndDrop(this.file)
            // setting a small time out for file to be picked before shipped
            setTimeout(() => {
                Axios({
                    url: `${process.env.VUE_APP_API_HTTP_ROOT}shipments/create`,
                    data: { file: this.fileContent },
                    method: 'POST'
                })
                    .then(resp => {
                        if (resp.status === 200) {
                            this.loading = false
                            this.$emit('confirmed')
                            this.okFunction()
                        } else {
                            this.loading = false
                            this.fileErrors = []
                        }
                    })
                    .catch(err => {
                        for (const errorGroupKey of Object.keys(err.response.data.errors)) {
                            const errorGroup = err.response.data.errors[errorGroupKey]
                            this.fileErrors = this.fileErrors.concat(errorGroup)
                        }
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Error!',
                                icon: 'XIcon',
                                text: '' + this.errors,
                                variant: 'danger',
                            },
                        })
                        this.loading = false
                    })
            }, 1000)
        },
        onEscapeKeyUp(event) {
            if (event.which === 27) {
                this.$emit('close')
            }
        },
    },
}
</script>
